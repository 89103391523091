<template>
  <div data-v-2b7575d8="" class="backclick">
    <div data-v-2b7575d8="" class="logo-container">
      <a data-v-2b7575d8="" href="https://www.clickdefense.io">
        <img data-v-2b7575d8="" class="imgLogInCD" src="/img/clickdefense-logo-small-dark.ec29e55c.png">
      </a>
    </div>
    <div data-v-2b7575d8="" errormessage="msgAuth">
      <!-- Login: -->
      <div v-if="authStep === '1'" data-test="sign-in-section" class="Form__formSection___3tqxz">
        <div data-test="sign-in-header-section" class="Section__sectionHeader___13iO4">Inicio de sesión</div>
        <div data-test="sign-in-body-section" class="Section__sectionBody___3DCrX">
          <div class="Form__formField___2DWhT">
            <div>
              <div class="Input__inputLabel___nQolz">Correo electrónico *</div>
              <input name="login_email" placeholder="Escribe tu correo electrónico" autofocus="autofocus" data-test="username-input" class="Input__input___2Sh1s" v-model="emailInput" @keyup="validateSignin" v-validate="{required: true, regex: /\S+@\S+\.\S+/ }">
            </div>
          </div>
          <div class="Form__formField___2DWhT">
            <div class="Input__inputLabel___nQolz">Contraseña * <span class="input-format-msg">(Mínimo 8 carácteres)</span></div>
            <input name="login_pass" type="password" placeholder="Escribe tu contraseña" data-test="sign-in-password-input" class="Input__input___2Sh1s" v-model="passInput" @keyup="validateSignin" v-validate="{required: true, regex: /[0-9a-zA-Z@#$%^&+!=]{8,}/
          }">
            <div class="Hint__hint___3Hrww">¿Has olvidado tu contraseña? <a id="resetPasswd" data-test="sign-in-forgot-password-link" class="Anchor__a___3JUCG" @click="linkResetPwdClick">Recuperar contraseña</a>
            </div>
          </div>
        </div>
        <div data-test="sign-in-footer-section" class="Section__sectionFooter___1QlDn">
          <span class="Section__sectionFooterPrimaryContent___36Gb1">
            <button id="logIn_Button" data-test="sign-in-sign-in-button" class="Button__button___1FrBC btn-continue" @click="btnEnter" v-bind:class="{butdisabled:!validSiginForm}">Entrar</button>
          </span>
          <!-- <span class="Section__sectionFooterSecondaryContent___3cjOa">¿No estás registrado? <a id="createAccLogIn" data-test="sign-in-create-account-link" class="Anchor__a___3JUCG font-weight-bold" @click="btnCreateAccount">Crear cuenta</a></span> -->
        </div>
      </div>

      <!-- Reset password: -->
      <div v-if="authStep === '2'" data-test="reset-pass-section" class="Form__formSection___3tqxz">
        <div data-test="res-pas-header-section" class="Section__sectionHeader___13iO4">Recupera tu contraseña</div>
        <div class="form-help-text">
          <p>Introduce tu correo electrónico para recuperar tu contraseña</p>
        </div>
        <div data-test="res-pas-body-section" class="Section__sectionBody___3DCrX">
          <div class="Form__formField___2DWhT">
            <div>
              <div class="Input__inputLabel___nQolz">Correo electrónico *</div>
              <input name="resetpass_email" placeholder="Correo electrónico" autofocus="autofocus" data-test="email-reset-input" class="Input__input___2Sh1s" v-model="resetPassInput" @keyup="validateResetPass">
            </div>
          </div>
        </div>
        <div data-test="res-pas-footer-section" class="Section__sectionFooter___1QlDn">
          <span class="Section__sectionFooterPrimaryContent___36Gb1">
            <button data-test="res-pas-reset-button" class="Button__button___1FrBC btn-continue" @click="btnResetPwdClick" v-bind:class="{butdisabled:!validResPwdForm}">Continuar</button>
          </span>
          <span class="Section__sectionFooterSecondaryContent___3cjOa">¿Ya tienes cuenta de ClickDefense? <a data-test="res-pas-create-account-link" class="Anchor__a___3JUCG" @click="btnAlreadyCdAcc">Ir al inicio</a>
          </span>
        </div>
      </div>

      <!-- Confirm reset password: -->
      <div v-if="authStep === '3'" data-test="confirm-reset-pass-section" class="Form__formSection___3tqxz">
        <div data-test="con-res-pas-header-section" class="Section__sectionHeader___13iO4">Recupera tu contraseña</div>
        <div class="form-help-text">
          <p>Acabamos de enviar un código de verificación a tu correo. Por favor, ponlo a continuación:</p>
        </div>
        <div data-test="con-res-pas-section" class="Section__sectionBody___3DCrX">
          <div class="Form__formField___2DWhT">
            <div>
              <div class="Input__inputLabel___nQolz">Correo electrónico</div>
              <input name="conrespas_email" data-test="email-reset-input" class="Input__input___2Sh1s" v-model="resetPassInputAsterisk" disabled>
            </div>
          </div>
          <div class="Form__formField___2DWhT">
            <div>
              <div class="Input__inputLabel___nQolz">Código de confirmación *</div>
              <input name="conrespas_code" placeholder="Código de confirmación" autofocus="autofocus" data-test="email-reset-input" class="Input__input___2Sh1s" v-model="codeConfirmInput" @keyup="validateConfirmReset">
            </div>
          </div>
          <div class="Form__formField___2DWhT">
            <div>
              <div class="Input__inputLabel___nQolz">Nueva contraseña * <span class="input-format-msg">(Mínimo 8 carácteres)</span></div>
              <input type="password" name="conrespas_pass" placeholder="Nueva contraseña" data-test="email-reset-input" class="Input__input___2Sh1s" v-model="newPassInput" @keyup="validateConfirmReset">
            </div>
          </div>
        </div>
        <div data-test="con-res-pas-footer-section" class="Section__sectionFooter___1QlDn">
          <span class="Section__sectionFooterPrimaryContent___36Gb1">
            <button data-test="con-res-pas-enter-button" class="Button__button___1FrBC btn-continue" @click="btnSetNewPwdClick" v-bind:class="{butdisabled:!validConfirmResPwdForm}">Enviar</button>
          </span>
          <span class="Section__sectionFooterSecondaryContent___3cjOa">¿Ya tienes cuenta de ClickDefense? <a data-test="con-res-pas-create-account-link" class="Anchor__a___3JUCG font-weight-bold" @click="btnAlreadyCdAcc">Iniciar sesión</a>
          </span>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { Auth } from 'aws-amplify'
import Amplify from 'aws-amplify'
import utils from '@/api/utils.js'

export default {
  name: 'MyComponent',
  props: [],
  data() {
    return {
      emailInput: '',
      passInput: '',
      resetPassInput: '',
      resetPassInputAsterisk: '',
      codeConfirmInput: '',
      newPassInput: '',
      validSiginForm: false,
      validResPwdForm: false,
      validConfirmResPwdForm: false,
      authStep: '1'
    }
  },
  methods: {
    btnCreateAccount() {
      this.$router.push({ path: '/signup' })
    },
    linkResetPwdClick() {
      this.resetForm()
      setTimeout(() => { this.authStep = '2' }, 100);
    },
    async btnResetPwdClick() {
      if (this.validResPwdForm) {
        this.$vs.loading()
        try {
          Auth.forgotPassword(this.resetPassInput).then(data => {
            if (data && data.CodeDeliveryDetails && data.CodeDeliveryDetails.AttributeName && data.CodeDeliveryDetails.AttributeName === 'email' &&
              data.CodeDeliveryDetails.Destination) {
              this.resetPassInputAsterisk = data.CodeDeliveryDetails.Destination
              this.authStep = '3'
              this.$vs.loading.close()
            } else {
              this.authStep = '1'
              this.resetForm(true)
              this.$vs.loading.close()
              this.$vs.notify({
                time: 10000,
                title: 'Error enviando email',
                text: 'Por favor vuelva a intentarlo mas tarde',
                color: 'danger',
                iconPack: 'feather',
                icon: 'icon-check',
                position: 'top-center'
              })
            }
          }).catch(() => {
            this.authStep = '1'
            this.resetForm(true)
            this.$vs.loading.close()
            this.$vs.notify({
              time: 10000,
              title: 'Error enviando email',
              text: 'Por favor vuelva a intentarlo mas tarde',
              color: 'danger',
              iconPack: 'feather',
              icon: 'icon-check',
              position: 'top-center'
            })
          })
        } catch (error) {
          this.authStep = '1'
          this.resetForm(true)
          this.$vs.loading.close()
          this.$vs.notify({
            time: 10000,
            title: 'Error enviando email',
            text: 'Por favor vuelva a intentarlo mas tarde',
            color: 'danger',
            iconPack: 'feather',
            icon: 'icon-check',
            position: 'top-center'
          })
        }
      }
    },
    async btnSetNewPwdClick() {
      if (this.validConfirmResPwdForm) {
        this.$vs.loading()
        try {
          Auth.forgotPasswordSubmit(this.resetPassInput, this.codeConfirmInput, this.newPassInput).then(() => {
            this.authStep = '1'
            this.resetForm(true)
            this.$vs.loading.close()
            this.$vs.notify({
              time: 10000,
              title: 'Nueva contraseña registrada correctamente',
              text: 'Puedes acceder al panel de control',
              color: 'success',
              iconPack: 'feather',
              icon: 'icon-check',
              position: 'top-center'
            })
          }).catch(() => {
            this.authStep = '1'
            this.resetForm(true)
            this.$vs.loading.close()
            this.$vs.notify({
              time: 10000,
              title: 'Error reseteando la contraseña',
              text: 'Por favor vuelva a intentarlo mas tarde',
              color: 'danger',
              iconPack: 'feather',
              icon: 'icon-check',
              position: 'top-center'
            })
          })
        } catch (error) {
          this.authStep = '1'
          this.resetForm(true)
          this.$vs.loading.close()
          this.$vs.notify({
            time: 10000,
            title: 'Error reseteando la contraseña',
            text: 'Por favor vuelva a intentarlo mas tarde',
            color: 'danger',
            iconPack: 'feather',
            icon: 'icon-check',
            position: 'top-center'
          })
        }
      }
    },
    async btnEnter() {
      if (this.validSiginForm) {
        this.$vs.loading()
        try {
          this.$store.dispatch('setEmail', this.emailInput)
          Auth.signIn(this.emailInput, this.passInput).then(() => {
            this.$vs.loading.close()
            // eslint-disable-next-line no-undef
            Froged('set', { email: this.$store.state.user.email, })
            this.$router.push({ path: '/google-accounts' })
          }).catch(() => {
            this.$vs.loading.close()
            this.$vs.notify({
              time: 10000,
              title: 'Error accediendo al panel de control',
              text: 'Compruebe el usuario y la contraseña',
              color: 'danger',
              iconPack: 'feather',
              icon: 'icon-check',
              position: 'top-center'
            })
          })
        } catch {
          this.$vs.loading.close()
          this.$vs.notify({
            time: 10000,
            title: 'Error accediendo al panel de control',
            text: 'Compruebe el usuario y la contraseña',
            color: 'danger',
            iconPack: 'feather',
            icon: 'icon-check',
            position: 'top-center'
          })
        }
        this.resetForm()
      }
    },
    btnAlreadyCdAcc() {
      this.authStep = '1'
      this.resetForm(true)
    },
    validateSignin() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.validSiginForm = true
          return true
        } else {
          this.validSiginForm = false
          return false
        }
      })
    },
    validateResetPass() {
      if (this.emailValid(this.resetPassInput)) {
        this.validResPwdForm = true
      } else {
        this.validResPwdForm = false
      }
    },
    validateConfirmReset() {
      if (this.confirmCodeValid(this.codeConfirmInput) && this.passwordValid(this.newPassInput)) {
        this.validConfirmResPwdForm = true
      } else {
        this.validConfirmResPwdForm = false
      }
    },
    emailValid(dataEmail) {
      const re = /\S+@\S+\.\S+/
      return re.test(dataEmail);
    },
    confirmCodeValid(dataCodeConfirm) {
      const re = /[0-9]{6,}/
      return re.test(dataCodeConfirm);
    },
    passwordValid(dataPassword) {
      const re = /[0-9a-zA-Z@#$%^&+!=]{8,}/
      return re.test(dataPassword);
    },
    resetForm(dataRsetEmail) {
      this.validSiginForm = false
      this.validResPwdForm = false
      this.validConfirmResPwdForm = false
      this.emailInput = ''
      this.passInput = ''
      if (dataRsetEmail) {
        this.resetPassInput = ''
        this.resetPassInputAsterisk = ''
      }
      this.codeConfirmInput = ''
      this.newPassInput = ''
    }
  },
  mounted() {
    Amplify.Auth.currentSession().then(apiSession => {
      const paramsCheckAdmin = apiSession.accessToken.payload['cognito:groups']
      this.$store.dispatch('updateAdmin', utils.isAdminUser(paramsCheckAdmin))
      if (this.$store.state.user.isAdmin && this.$store.state.user.mainAccount !== '') {
        this.$acl.change('admin')
      } else if (this.$store.state.user.isAdmin && this.$store.state.user.mainAccount === '') {
        this.$vs.loading.close()
        this.$acl.change('admin')
        this.$router.push('/admin-page')
      } else {
        this.$vs.loading.close()
        this.$router.push('/google-accounts')
      }
    }).catch(() => { })
  },
}                          
</script>
<style scoped>
#app > div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}
#app > div > div:nth-child(2) {
  margin: 0 auto;
}
.logo-container {
  width: 100%;
  text-align: center;
  margin: 1% auto;
}
</style>
<style>
.texthead {
  font-weight: bold;
}
.textdisabled {
  color: gainsboro;
}
.butdisabled {
  background-color: rgba(125, 78, 197, 0.5) !important;
}
.textar {
  width: 100%;
  margin: 10px 0px;
}
.textar > div {
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 10px;
}
#conditions {
  height: 250px;
  text-align: justify;
  margin: 1% 0;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
}
.Button__button___1FrBC {
  background-color: rgb(125, 78, 197);
}
.Form__formSection___3tqxz {
  display: flex;
  flex-direction: column;
  margin: 5px auto;
  width: 35vw;
  min-width: 360px;
  padding: 20px;
}
@media only screen and (max-width: 1280px) {
  .Form__formSection___3tqxz {
    width: 50vw;
  }
}
.Section__sectionBody___3DCrX {
  margin-bottom: 0;
}
.Section__sectionHeader___13iO4 {
  text-align: center;
}
#scrolldowncheck {
  margin-top: 20px;
}
.error {
  color: #ff0000;
}
.backclick {
  background-image: url(../../assets/images/pages/bg-login.png);
  background-size: cover;
  min-height: 100vh;
}
.input-format-msg {
  font-size: 0.9rem;
  margin-left: 10px;
}
.btn-continue {
  outline: none !important;
}
.Anchor__a___3JUCG {
  color: #3366bb !important;
}
.Anchor__a___3JUCG:hover {
  text-decoration-line: underline !important;
}
.imgLogInCD {
  width: 65%;
  max-width: 469px;
}
</style>