var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "backclick", attrs: { "data-v-2b7575d8": "" } },
    [
      _vm._m(0),
      _c("div", { attrs: { "data-v-2b7575d8": "", errormessage: "msgAuth" } }, [
        _vm.authStep === "1"
          ? _c(
              "div",
              {
                staticClass: "Form__formSection___3tqxz",
                attrs: { "data-test": "sign-in-section" }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "Section__sectionHeader___13iO4",
                    attrs: { "data-test": "sign-in-header-section" }
                  },
                  [_vm._v("Inicio de sesión")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "Section__sectionBody___3DCrX",
                    attrs: { "data-test": "sign-in-body-section" }
                  },
                  [
                    _c("div", { staticClass: "Form__formField___2DWhT" }, [
                      _c("div", [
                        _c(
                          "div",
                          { staticClass: "Input__inputLabel___nQolz" },
                          [_vm._v("Correo electrónico *")]
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.emailInput,
                              expression: "emailInput"
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: { required: true, regex: /\S+@\S+\.\S+/ },
                              expression:
                                "{required: true, regex: /\\S+@\\S+\\.\\S+/ }"
                            }
                          ],
                          staticClass: "Input__input___2Sh1s",
                          attrs: {
                            name: "login_email",
                            placeholder: "Escribe tu correo electrónico",
                            autofocus: "autofocus",
                            "data-test": "username-input"
                          },
                          domProps: { value: _vm.emailInput },
                          on: {
                            keyup: _vm.validateSignin,
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.emailInput = $event.target.value
                            }
                          }
                        })
                      ])
                    ]),
                    _c("div", { staticClass: "Form__formField___2DWhT" }, [
                      _vm._m(1),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.passInput,
                            expression: "passInput"
                          },
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: {
                              required: true,
                              regex: /[0-9a-zA-Z@#$%^&+!=]{8,}/
                            },
                            expression:
                              "{required: true, regex: /[0-9a-zA-Z@#$%^&+!=]{8,}/\n        }"
                          }
                        ],
                        staticClass: "Input__input___2Sh1s",
                        attrs: {
                          name: "login_pass",
                          type: "password",
                          placeholder: "Escribe tu contraseña",
                          "data-test": "sign-in-password-input"
                        },
                        domProps: { value: _vm.passInput },
                        on: {
                          keyup: _vm.validateSignin,
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.passInput = $event.target.value
                          }
                        }
                      }),
                      _c("div", { staticClass: "Hint__hint___3Hrww" }, [
                        _vm._v("¿Has olvidado tu contraseña? "),
                        _c(
                          "a",
                          {
                            staticClass: "Anchor__a___3JUCG",
                            attrs: {
                              id: "resetPasswd",
                              "data-test": "sign-in-forgot-password-link"
                            },
                            on: { click: _vm.linkResetPwdClick }
                          },
                          [_vm._v("Recuperar contraseña")]
                        )
                      ])
                    ])
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "Section__sectionFooter___1QlDn",
                    attrs: { "data-test": "sign-in-footer-section" }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass:
                          "Section__sectionFooterPrimaryContent___36Gb1"
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "Button__button___1FrBC btn-continue",
                            class: { butdisabled: !_vm.validSiginForm },
                            attrs: {
                              id: "logIn_Button",
                              "data-test": "sign-in-sign-in-button"
                            },
                            on: { click: _vm.btnEnter }
                          },
                          [_vm._v("Entrar")]
                        )
                      ]
                    )
                  ]
                )
              ]
            )
          : _vm._e(),
        _vm.authStep === "2"
          ? _c(
              "div",
              {
                staticClass: "Form__formSection___3tqxz",
                attrs: { "data-test": "reset-pass-section" }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "Section__sectionHeader___13iO4",
                    attrs: { "data-test": "res-pas-header-section" }
                  },
                  [_vm._v("Recupera tu contraseña")]
                ),
                _vm._m(2),
                _c(
                  "div",
                  {
                    staticClass: "Section__sectionBody___3DCrX",
                    attrs: { "data-test": "res-pas-body-section" }
                  },
                  [
                    _c("div", { staticClass: "Form__formField___2DWhT" }, [
                      _c("div", [
                        _c(
                          "div",
                          { staticClass: "Input__inputLabel___nQolz" },
                          [_vm._v("Correo electrónico *")]
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.resetPassInput,
                              expression: "resetPassInput"
                            }
                          ],
                          staticClass: "Input__input___2Sh1s",
                          attrs: {
                            name: "resetpass_email",
                            placeholder: "Correo electrónico",
                            autofocus: "autofocus",
                            "data-test": "email-reset-input"
                          },
                          domProps: { value: _vm.resetPassInput },
                          on: {
                            keyup: _vm.validateResetPass,
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.resetPassInput = $event.target.value
                            }
                          }
                        })
                      ])
                    ])
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "Section__sectionFooter___1QlDn",
                    attrs: { "data-test": "res-pas-footer-section" }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass:
                          "Section__sectionFooterPrimaryContent___36Gb1"
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "Button__button___1FrBC btn-continue",
                            class: { butdisabled: !_vm.validResPwdForm },
                            attrs: { "data-test": "res-pas-reset-button" },
                            on: { click: _vm.btnResetPwdClick }
                          },
                          [_vm._v("Continuar")]
                        )
                      ]
                    ),
                    _c(
                      "span",
                      {
                        staticClass:
                          "Section__sectionFooterSecondaryContent___3cjOa"
                      },
                      [
                        _vm._v("¿Ya tienes cuenta de ClickDefense? "),
                        _c(
                          "a",
                          {
                            staticClass: "Anchor__a___3JUCG",
                            attrs: {
                              "data-test": "res-pas-create-account-link"
                            },
                            on: { click: _vm.btnAlreadyCdAcc }
                          },
                          [_vm._v("Ir al inicio")]
                        )
                      ]
                    )
                  ]
                )
              ]
            )
          : _vm._e(),
        _vm.authStep === "3"
          ? _c(
              "div",
              {
                staticClass: "Form__formSection___3tqxz",
                attrs: { "data-test": "confirm-reset-pass-section" }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "Section__sectionHeader___13iO4",
                    attrs: { "data-test": "con-res-pas-header-section" }
                  },
                  [_vm._v("Recupera tu contraseña")]
                ),
                _vm._m(3),
                _c(
                  "div",
                  {
                    staticClass: "Section__sectionBody___3DCrX",
                    attrs: { "data-test": "con-res-pas-section" }
                  },
                  [
                    _c("div", { staticClass: "Form__formField___2DWhT" }, [
                      _c("div", [
                        _c(
                          "div",
                          { staticClass: "Input__inputLabel___nQolz" },
                          [_vm._v("Correo electrónico")]
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.resetPassInputAsterisk,
                              expression: "resetPassInputAsterisk"
                            }
                          ],
                          staticClass: "Input__input___2Sh1s",
                          attrs: {
                            name: "conrespas_email",
                            "data-test": "email-reset-input",
                            disabled: ""
                          },
                          domProps: { value: _vm.resetPassInputAsterisk },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.resetPassInputAsterisk = $event.target.value
                            }
                          }
                        })
                      ])
                    ]),
                    _c("div", { staticClass: "Form__formField___2DWhT" }, [
                      _c("div", [
                        _c(
                          "div",
                          { staticClass: "Input__inputLabel___nQolz" },
                          [_vm._v("Código de confirmación *")]
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.codeConfirmInput,
                              expression: "codeConfirmInput"
                            }
                          ],
                          staticClass: "Input__input___2Sh1s",
                          attrs: {
                            name: "conrespas_code",
                            placeholder: "Código de confirmación",
                            autofocus: "autofocus",
                            "data-test": "email-reset-input"
                          },
                          domProps: { value: _vm.codeConfirmInput },
                          on: {
                            keyup: _vm.validateConfirmReset,
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.codeConfirmInput = $event.target.value
                            }
                          }
                        })
                      ])
                    ]),
                    _c("div", { staticClass: "Form__formField___2DWhT" }, [
                      _c("div", [
                        _vm._m(4),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.newPassInput,
                              expression: "newPassInput"
                            }
                          ],
                          staticClass: "Input__input___2Sh1s",
                          attrs: {
                            type: "password",
                            name: "conrespas_pass",
                            placeholder: "Nueva contraseña",
                            "data-test": "email-reset-input"
                          },
                          domProps: { value: _vm.newPassInput },
                          on: {
                            keyup: _vm.validateConfirmReset,
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.newPassInput = $event.target.value
                            }
                          }
                        })
                      ])
                    ])
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "Section__sectionFooter___1QlDn",
                    attrs: { "data-test": "con-res-pas-footer-section" }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass:
                          "Section__sectionFooterPrimaryContent___36Gb1"
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "Button__button___1FrBC btn-continue",
                            class: { butdisabled: !_vm.validConfirmResPwdForm },
                            attrs: { "data-test": "con-res-pas-enter-button" },
                            on: { click: _vm.btnSetNewPwdClick }
                          },
                          [_vm._v("Enviar")]
                        )
                      ]
                    ),
                    _c(
                      "span",
                      {
                        staticClass:
                          "Section__sectionFooterSecondaryContent___3cjOa"
                      },
                      [
                        _vm._v("¿Ya tienes cuenta de ClickDefense? "),
                        _c(
                          "a",
                          {
                            staticClass: "Anchor__a___3JUCG font-weight-bold",
                            attrs: {
                              "data-test": "con-res-pas-create-account-link"
                            },
                            on: { click: _vm.btnAlreadyCdAcc }
                          },
                          [_vm._v("Iniciar sesión")]
                        )
                      ]
                    )
                  ]
                )
              ]
            )
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "logo-container", attrs: { "data-v-2b7575d8": "" } },
      [
        _c(
          "a",
          {
            attrs: {
              "data-v-2b7575d8": "",
              href: "https://www.clickdefense.io"
            }
          },
          [
            _c("img", {
              staticClass: "imgLogInCD",
              attrs: {
                "data-v-2b7575d8": "",
                src: "/img/clickdefense-logo-small-dark.ec29e55c.png"
              }
            })
          ]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "Input__inputLabel___nQolz" }, [
      _vm._v("Contraseña * "),
      _c("span", { staticClass: "input-format-msg" }, [
        _vm._v("(Mínimo 8 carácteres)")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-help-text" }, [
      _c("p", [
        _vm._v("Introduce tu correo electrónico para recuperar tu contraseña")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-help-text" }, [
      _c("p", [
        _vm._v(
          "Acabamos de enviar un código de verificación a tu correo. Por favor, ponlo a continuación:"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "Input__inputLabel___nQolz" }, [
      _vm._v("Nueva contraseña * "),
      _c("span", { staticClass: "input-format-msg" }, [
        _vm._v("(Mínimo 8 carácteres)")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }